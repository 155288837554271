<template>
    <div class="formbox">
        <div class="theader">
            <span class="title" style="width: 300px">名称</span>
            <span class="title" style="width: 800px">action</span>
            <Button @click="addList" type="primary" style="width: 40px; margin-left: 10px" icon="md-add"></Button>
        </div>
        <div class="tcontent">
            <div v-for="(item, index) in list" :key="index" style="margin-bottom: 10px">
                <Input :maxlength="20" v-model="item.name" style="width: 290px; margin-right: 10px" />
                <Input :maxlength="20" v-model="item.action" style="width: 800px" />
                <Button @click="remove(index)" v-if="!item.id" type="error" style="width: 40px; margin-left: 10px" icon="md-close"></Button>
                <Button @click="deletei(item.id, index)" v-if="item.id" type="error" style="width: 40px; margin-left: 10px" icon="ios-trash-outline"></Button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    // props:['list'],
    components: {},
    data() {
        //这里存放数据
        return {
            list: [],
        }
    },
    methods: {
        addList() {
            if (!(this.list[0].name && this.list[0].action)) {
                this.$Message.warning({
                    content: "请补充完整",
                    background: true,
                })
                return
            }
            this.list.unshift({
                name: "",
                action: "",
            })
        },
        remove(index) {
            this.list.splice(index, 1)
        },
        deletei(id, index) {
            try {
                this.$post("/gateway/apps/basic/api/base/finger/element/deleteAppColumnBlack", {
                    id,
                }).then(res => {
                    if (res.code == 200) {
                        this.$Message.success({
                            content: "删除成功",
                            background: true,
                        })
                        this.list.splice(index, 1)
                    } else {
                        this.$Message.error({
                            content: "删除失败",
                            background: true,
                        })
                    }
                })
            } catch (err) {
                console.log(err)
                this.$Message.error({
                    content: "删除失败",
                    background: true,
                })
            }
        },
        getList() {
            this.$get("/gateway/apps/basic/api/base/finger/element/selectAppColumnBlack")
                .then(res => {
                    if (res.code == 200) {
                        this.list = res.dataList
                    } else {
                        this.$Message.error({
                            content: "数据获取失败",
                            background: true,
                        })
                    }
                })
                .catch(err => {
                  console.log(err)
                    this.$Message.error({
                        content: "数据获取失败",
                        background: true,
                    })
                })
        },
    },
    watch: {
        list: {
            handler(val) {
                this.$emit("getList", val)
            },
        },
    },
    created() {},
}
</script>
<style lang='less' scoped>
//编写css
.formbox {
    position: relative;
    margin: 0 0 0 30px;
    .theader {
        // padding: 10px 0 0 0;
        position: sticky;
        top: 10px;
        left: 0;
        background: #fff;
        z-index: 99;
        .title {
            display: inline-block;
            font-weight: 700;
        }
    }
    .tcontent {
        margin-top: 10px;
    }
}
</style>